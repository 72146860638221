var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("app-navigation", {
        attrs: { title: "Then", actionButtonIcon: "fa fa-save" },
        on: {
          action: function($event) {
            return _vm.$router.go(-1)
          }
        }
      }),
      _vm.description
        ? _c("div", {
            staticClass: "description",
            domProps: { innerHTML: _vm._s(_vm.description) }
          })
        : _vm._e(),
      _vm._l(_vm.actions, function(action, index) {
        return _c("div", { key: index, staticStyle: { margin: "15px" } }, [
          _c(
            "span",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "150px" },
                  attrs: {
                    size: "mini",
                    placeholder: _vm.$t("triggers.select-method"),
                    loading: _vm.loading
                  },
                  model: {
                    value: action.method,
                    callback: function($$v) {
                      _vm.$set(action, "method", $$v)
                    },
                    expression: "action.method"
                  }
                },
                _vm._l(
                  _vm.actionMethods.filter(function(act) {
                    return _vm.showComponent(act.value)
                  }),
                  function(method, index) {
                    return _c("el-option", {
                      key: method.value,
                      attrs: {
                        label: _vm.$t(method.title),
                        value: method.value
                      }
                    })
                  }
                ),
                1
              ),
              action.method
                ? _c(_vm.actionComponents[action.method].component, {
                    tag: "component",
                    staticStyle: { "margin-left": "5px" },
                    attrs: { action: action }
                  })
                : _vm._e(),
              _c("i", {
                staticClass: "fa fa-trash-alt remove-btn",
                on: {
                  click: function($event) {
                    return _vm.removeAction(index)
                  }
                }
              })
            ],
            1
          )
        ])
      }),
      _c(
        "el-button",
        {
          staticStyle: { margin: "15px" },
          attrs: { type: "primary", icon: "el-icon-plus" },
          on: { click: _vm.addAction }
        },
        [_vm._v(_vm._s(_vm.$t("triggers.add-action")))]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }