var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("el-input-number", {
        staticStyle: { width: "120px" },
        attrs: { size: "mini" },
        on: { change: _vm.changeTime },
        model: {
          value: _vm.time,
          callback: function($$v) {
            _vm.time = $$v
          },
          expression: "time"
        }
      }),
      _c(
        "el-select",
        {
          staticStyle: { width: "100px", "margin-left": "5px" },
          attrs: { size: "mini" },
          model: {
            value: _vm.selectedUnit,
            callback: function($$v) {
              _vm.selectedUnit = $$v
            },
            expression: "selectedUnit"
          }
        },
        [
          _c("el-option", { attrs: { label: "ms", value: "ms" } }),
          _c("el-option", { attrs: { label: "s", value: "s" } }),
          _c("el-option", { attrs: { label: "min", value: "min" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }