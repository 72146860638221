<template>
  <span>

    <el-select style="width: 120px" size="mini"
               v-model="action.params.id"
               :placeholder="$t('triggers.select-accessory')"
               @change="changeId">

      <el-option v-for="(accessory, index) in data"
                 :key="accessory.id"
                 :label="accessory.name"
                 :value="accessory.id">
      </el-option>

    </el-select>

    <el-select style="width: 120px; margin-left: 5px" size="mini"
               v-model="action.params.status"
               :placeholder="$t('triggers.select-value')"
               @change="changeSwitch">

      <el-option v-for="(value, index) in values"
                 :key="value.value"
                 :label="$t(value.title)"
                 :value="value.value">
      </el-option>

    </el-select>

  </span>
</template>

<script>
  import {group, ACCESSORY_CATEGORY_BINARY_BUTTON} from "@/utils/accessory-utils";
  export default {
    name: "BinaryAction",

    props: {
      action: {}
    },

    data: function(){
      return {
        values: [
          {title: "triggers.action-on", value: true},
          {title: "triggers.action-off", value: false}
        ],
        data: []
      }
    },

    computed: {
      accessories: function () {
        let switches = this.$store.getters.accessories.filter(accessory => accessory.category == ACCESSORY_CATEGORY_BINARY_BUTTON)
        console.log("switches", switches)
        return switches
      }
    },


    methods: {
      group,

      changeId: function () {
        this.updateDescription()
        console.log("changeId", this.action.description)
      },

      changeSwitch: function () {
        this.updateDescription()
        console.log("changeSwitch", this.action.description)
      },

      updateDescription: function(){
        if('en' === localStorage.getItem("lang"))
          this.action.description = this.getSwitchDescription() + " " + this.getIdDescription()
        else
          this.action.description = this.getIdDescription() + " " + this.getSwitchDescription()
      },

      getSwitchDescription: function () {
        if (this.action.params.status == undefined)
          return ""
        return this.action.params.status ? this.$t("triggers.action-on") : this.$t("triggers.action-off")
      },

      getIdDescription: function () {
        let acc = this.data.find(acc => acc.id == this.action.params.id)
        return acc ? acc.name : ""
      }
    },

    activated(){
      console.log("BinaryAction Activated")
    },

    created(){

      this.data = this.$store.getters.accessories.filter(accessory => accessory.category == ACCESSORY_CATEGORY_BINARY_BUTTON)
      console.log("BinaryAction Created", this.data)
    }
  }
</script>

<style scoped>

</style>