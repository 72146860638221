<template>
  <span>

    <el-select style="width: 120px" size="mini"
               v-model="action.params.mode"
               :placeholder="$t('triggers.select-mode')"
               @change="changeMode">

      <el-option v-for="(mode, index) in modes"
                 :key="mode.value"
                 :label="$t(mode.title)"
                 :value="mode.value">
      </el-option>

    </el-select>

  </span>
</template>

<script>
  export default {
    name: "SecurityModeAction",

    props: {
      action: {}
    },

    data: function(){
      return {
        modes: [
          {title: "triggers.arm-away", value: 1},
          {title: "triggers.arm-stay", value: 3},
          {title: "triggers.disarmed", value: 2},
        ]
      }
    },

    methods: {
      changeMode: function () {
        if (!this.action.params.mode)
          this.action.description = ""

        let mode = this.modes.find(mode => mode.value == this.action.params.mode)
        if (!mode)
          this.action.description = ""

        if('en' === localStorage.getItem("lang"))
          this.action.description = "Change security mode as " + this.$t(mode.title)
        else
          this.action.description = "Güvenlik modunu " + this.$t(mode.title) +  " olarak değiştir"
        console.log("changeMode", this.action.description)
      }
    }
  }
</script>

<style scoped>

</style>