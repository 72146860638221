var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("el-input", {
        staticStyle: { width: "160px", "margin-left": "5px" },
        attrs: {
          size: "mini",
          type: "text",
          placeholder: _vm.$t("triggers.title")
        },
        on: { input: _vm.changeTitle },
        model: {
          value: _vm.action.params.title,
          callback: function($$v) {
            _vm.$set(_vm.action.params, "title", $$v)
          },
          expression: "action.params.title"
        }
      }),
      _c("el-input", {
        staticStyle: { width: "300px", "margin-left": "5px" },
        attrs: {
          size: "mini",
          type: "text",
          placeholder: _vm.$t("triggers.message")
        },
        on: { input: _vm.changeMessage },
        model: {
          value: _vm.action.params.message,
          callback: function($$v) {
            _vm.$set(_vm.action.params, "message", $$v)
          },
          expression: "action.params.message"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }