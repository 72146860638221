<template>
  <span>

    <el-select style="width: 120px" size="mini"
               v-model="action.params.id"
               :placeholder="$t('triggers.select-scene')"
               @change="changeId">

      <el-option v-for="(scene, index) in $store.getters.scenes"
                 :key="scene.id"
                 :label="scene.name"
                 :value="scene.id">
      </el-option>

    </el-select>


  </span>
</template>

<script>
  export default {
    name: "SceneAction",

    props: {
      action: {}
    },

    methods: {
      changeId: function () {
        if (!this.action.params.id)
          this.action.description = ""

        let scene = this.$store.getters.scenes.find(scene => scene.id == this.action.params.id)
        if (!scene)
          this.action.description = ""

        if('en' === localStorage.getItem("lang"))
          this.action.description = `Run ${scene.name} scene`
        else
          this.action.description = `${scene.name} senaryosunu çalıştır`
        console.log("changeId", this.action.description)
      },
    }
  }
</script>

<style scoped>

</style>