<template>
  <span>

    <el-select style="width: 120px" size="mini"
               v-model="action.params.method"
               :placeholder="$t('triggers.select-prop')"
               @change="changeMethod">

      <el-option v-for="(method, index) in methods"
                 :key="method.value"
                 :label="$t(method.title)"
                 :value="method.value">
      </el-option>

    </el-select>

    <el-input v-if="action.params.method == 'setTargetPoint'" type="number" style="width: 120px; margin-left: 5px" size="mini" v-model="action.params.setPoint" @input="changeSetPoint"/>


    <span v-if="action.params.method == 'setThermostatMode'">
      <el-select style="width: 120px; margin-left: 5px" size="mini"
                 v-model="action.params.component"
                 :placeholder="$t('triggers.select-value')"
                 @change="changeComponent">

        <el-option v-for="(component, index) in components"
                   :key="component.type"
                   :label="component.title"
                   :value="component.type">
        </el-option>

      </el-select>

      <el-select v-if="modes.length > 0" style="width: 120px; margin-left: 5px" size="mini"
                 v-model="action.params.mode"
                 :placeholder="$t('triggers.select-value')"
                 @change="changeMode">

        <el-option v-for="(mode, index) in modes"
                   :key="mode.type"
                   :label="mode.title"
                   :value="mode.type">
        </el-option>

      </el-select>
    </span>

  </span>
</template>

<script>
  export default {
    name: "ThermostatAction",

    props: {
      action: {},
      accessory: {}
    },

    data: function(){
      return {
        methods: [
          {title: "triggers.change-temperature", value: "setTargetPoint"},
          {title: "triggers.set-thermostat-mode", value: "setThermostatMode"}
        ]
      }
    },


    computed: {
      components: function () {
        return this.accessory.components
      },
      modes: function () {
        if (this.action.params.component) {
          return this.components.find(comp => comp.type == this.action.params.component).modes
        }
        return []
      }
    },


    methods: {
      changeMethod: function () {
        this.reset()

        this.updateDescription()
        console.log("changeMethod", this.action.description)
      },

      changeSetPoint: function () {
        this.updateDescription()
        console.log("changeSetPoint", this.action.description)
      },

      changeComponent: function(){
        this.updateDescription()
      },

      changeMode: function(){
        this.updateDescription()
      },

      updateDescription: function(){
        if (this.action.params.method == "setTargetPoint") {
          this.action.description = this.$t("triggers.desc.action.set-point", { setpoint: this.action.params.setPoint, name: this.accessory.name})
        } else if (this.action.params.method == "setThermostatMode") {
          this.action.description = this.$t("triggers.desc.action.thermostat-mode", { component: this.getComponentDescription(), mode: this.getModeDescription(), name: this.accessory.name})
        }
      },

      getComponentDescription: function () {
        const component = this.components.find(comp => comp.type == this.action.params.component)
        return component ? component.title : "..."
      },

      getModeDescription: function () {
        const mode = this.modes.find(mode => mode.type == this.action.params.mode)
        return mode ? mode.title : "..."
      },

      reset: function () {
        if (this.action.params.method == "setTargetPoint") {
          delete this.action.params.component
          delete this.action.params.mode
        } else if (this.action.params.method == "setThermostatMode") {
          delete this.action.params.level
        }


        console.log("reset", this.action.params)
      }
    },


    created(){
      console.log("thermostat created")
    },

    activated(){
      console.log("thermostat activated")
    }

  }
</script>

<style scoped>

</style>