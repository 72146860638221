<template>
  <div>
    <app-navigation title="Then" actionButtonIcon="fa fa-save" @action="$router.go(-1)"></app-navigation>

    <div v-if="description" class="description" v-html="description "></div>

    <div style="margin: 15px" v-for="(action, index) in actions" :key="index">

      <span>
        <el-select style="width: 150px" size="mini"
                   v-model="action.method"
                   :placeholder="$t('triggers.select-method')"
                  :loading="loading">
          <el-option v-for="(method, index) in actionMethods.filter(act => showComponent(act.value))"
                     :key="method.value"
                     :label="$t(method.title)"
                     :value="method.value">
          </el-option>
        </el-select>

        <component style="margin-left: 5px;" v-if="action.method" :is="actionComponents[action.method].component" :action="action"></component>
        <i class="fa fa-trash-alt remove-btn" @click="removeAction(index)"></i>
      </span>

    </div>


    <el-button style="margin: 15px" type="primary" icon="el-icon-plus" @click="addAction">{{$t('triggers.add-action')}}</el-button>

  </div>
</template>

<script>
  import {group, ACCESSORY_GROUP_LIGHTING, ACCESSORY_GROUP_HVAC, ACCESSORY_GROUP_SHUTTER, ACCESSORY_GROUP_ONOFF, ACCESSORY_CATEGORY_SHUTTER, ACCESSORY_CATEGORY_DIMMER, ACCESSORY_CATEGORY_PUNCH_BUTTON,
    ACCESSORY_CATEGORY_BINARY_BUTTON, ACCESSORY_CATEGORY_ADVANCED_THERMOSTAT, ACCESSORY_CATEGORY_CONTACTOR, ACCESSORY_CATEGORY_SIREN} from "@/utils/accessory-utils";

  import Navigation from '@/components/Navigation'
  import DimmerLevelAction from "./actions/DimmerLevelAction"
  import KnxValueAction from "./actions/KnxValueAction"
  import SceneAction from "./actions/SceneAction"
  import SecurityModeAction from "./actions/SecurityModeAction"
  import SetPointAction from "./actions/SetPointAction"
  import ShutterLevelAction from "./actions/ShutterLevelAction"
  import SwitchAction from "./actions/SwitchAction"
  import PunchAction from "./actions/PunchAction"
  import BinaryAction from "./actions/BinaryAction"
  import ThermostatModeAction from "./actions/ThermostatModeAction"
  import DelayAction from "./actions/DelayAction"
  import ContactAction from "./actions/ContactAction"
  import HttpRequestAction from "./actions/HttpRequestAction"
  import PushNotificationAction from "./actions/PushNotificationAction"
  import AccessoryAction from "./actions/AccessoryAction"
  import {actionMethods} from "@/utils/trigger-utils";

  export default {
    name: "Then",
    components: {
      'app-navigation': Navigation,
      DimmerLevelAction,
      KnxValueAction,
      SceneAction,
      SecurityModeAction,
      SetPointAction,
      ShutterLevelAction,
      SwitchAction,
      DelayAction,
      ContactAction,
      HttpRequestAction,
      PushNotificationAction,
      PunchAction,
      BinaryAction,
      ThermostatModeAction,
      AccessoryAction
    },

    data: function(){
      return {
        actionMethods,
        actionComponents: {
          /*setPowerOn: { component: "SwitchAction", data: true },
          punch: { component: "PunchAction", data: true },
          binaryAction: { component: "BinaryAction", data: true },
          setShutterLevel: { component: "ShutterLevelAction", data: true },
          setDimmerLevel: { component: "DimmerLevelAction", data: true },
          setTargetPoint: { component: "SetPointAction", data: true },
          setThermostatMode: { component: "ThermostatModeAction", data: true },
          sendKnxValue: { component: "KnxValueAction", data: false },
          runScene: { component: "SceneAction", data: true },
          changeSecurityMode: { component: "SecurityModeAction", data: false },
          delay: { component: "DelayAction", data: false },
          callContact: { component: "ContactAction", data: true },
          sendHttpRequest: { component: "HttpRequestAction", data: false },
          sendPushNotification: { component: "PushNotificationAction", data: false },*/

          accessory: { component: "AccessoryAction", data: true },
          sendKnxValue: { component: "KnxValueAction", data: false },
          runScene: { component: "SceneAction", data: false },
          changeSecurityMode: { component: "SecurityModeAction", data: false },
          delay: { component: "DelayAction", data: false },
          callContact: { component: "ContactAction", data: true },
          sendHttpRequest: { component: "HttpRequestAction", data: false },
          sendPushNotification: { component: "PushNotificationAction", data: false },
        },

        description: "",
        loading: false
      }
    },

    computed: {
      actions: function () {
        return this.$store.getters.triggerData.actions
      },

      accessory: function(){
        return this.$store.getters.accessories.filter(accessory => accessory.group == ACCESSORY_GROUP_LIGHTING || accessory.group == ACCESSORY_GROUP_HVAC
          || accessory.group == ACCESSORY_GROUP_SHUTTER || accessory.group == ACCESSORY_GROUP_ONOFF)
      },

      setPowerOn: function () {
        return this.$store.getters.accessories.filter(accessory => this.hasPowerOnFeature(accessory))
      },
      punch: function () {
        return this.$store.getters.accessories.filter(accessory => accessory.category == ACCESSORY_CATEGORY_PUNCH_BUTTON)
      },
      binaryAction: function () {
        return this.$store.getters.accessories.filter(accessory => accessory.category == ACCESSORY_CATEGORY_BINARY_BUTTON)
      },
      setDimmerLevel: function(){
        return this.$store.getters.accessories.filter(accessory => accessory.category == ACCESSORY_CATEGORY_DIMMER)
      },
      setShutterLevel: function () {
        return this.$store.getters.accessories.filter(accessory => accessory.category == ACCESSORY_CATEGORY_SHUTTER)
      },
      setTargetPoint: function () {
        return this.$store.getters.accessories.filter(accessory => group(accessory.category) == ACCESSORY_GROUP_HVAC)
      },
      runScene: function () {
        return this.$store.getters.scenes
      },
      callContact: function () {
        return this.$store.getters.contacts
      },
      setThermostatMode: function () {
        return this.$store.getters.accessories.filter(accessory => accessory.category == ACCESSORY_CATEGORY_ADVANCED_THERMOSTAT)
      }
    },

    watch: {
      actions: {
        deep: true,
        immediate: true,
        handler: function (val) {
          console.log("actions", JSON.stringify(val, null, 2))

          this.updateDescription()
        }
      }
    },

    methods: {
      hasPowerOnFeature: function(accessory){
        return group(accessory.category) == ACCESSORY_GROUP_LIGHTING || (accessory.category >= ACCESSORY_CATEGORY_CONTACTOR && accessory.category <= ACCESSORY_CATEGORY_SIREN)
      },

      addAction: function () {
        this.$store.commit("addTriggerAction", {params: {}})
      },

      removeAction: function (index) {
        this.$store.commit("removeTriggerAction", index)
      },

      updateDescription: function () {
        this.description = this.actions.map(action => action.description).join(", ")
      },

      showComponent: function (method) {
        if (!this.actionComponents[method].data)
          return true

        return this[method] && this[method].length > 0
      },

      fetchData(){
        this.loading = true
        Promise.all([
          this.$store.dispatch("getAccessories"),
          this.$store.dispatch("getScenes"),
          this.$store.dispatch("getContacts"),
        ]).then(res => {
          this.loading = false
        })
      }
    },

    created(){

      console.log("this", this["scenes"])
      this.updateDescription()
      this.fetchData()
    }
  }
</script>

<style scoped>
  .remove-btn {
    cursor: pointer;
    margin-left: 10px;
    color: #545c64
  }
  .remove-btn:hover {
    color: #999;
  }
  .description {
    display: flex;
    justify-content: center;
    margin: 10px 15px 25px 15px;
    padding: 15px;
    border: 1px solid #eeeeee;
    background-color: #fff;
  }
</style>