<template>
  <span>

    <el-select style="width: 120px" size="mini"
               v-model="action.params.id"
               :placeholder="$t('triggers.select-accessory')"
               @change="changeId">

      <el-option v-for="(accessory, index) in accessories"
                 :key="accessory.id"
                 :label="accessory.name"
                 :value="accessory.id">
      </el-option>

    </el-select>

    <el-input type="number" style="width: 120px; margin-left: 5px" size="mini" v-model="action.params.level" @input="changeLevel"/>

  </span>
</template>

<script>
  import {ACCESSORY_CATEGORY_DIMMER} from "@/utils/accessory-utils";
  export default {
    name: "DimmerLevelAction",

    props: {
      action: {}
    },

    computed: {
      accessories: function () {
        return this.$store.getters.accessories.filter(accessory => accessory.category == ACCESSORY_CATEGORY_DIMMER)
      }
    },

    methods: {
      changeId: function () {
        this.updateDescription()
        console.log("changeId", this.action.description)
      },

      changeLevel: function () {
        this.updateDescription()
        console.log("changeLevel", this.action.description)
      },

      updateDescription: function(){
        this.action.description = this.getLevelDescription()
      },

      getLevelDescription: function () {
        if (this.action.params.level == undefined)
          return this.getIdDescription()

        if('en' === localStorage.getItem("lang"))
          return `Set ${this.getIdDescription()}dim level to ${this.action.params.level}`
        else
          return `${this.getIdDescription()}dim seviyesini ${this.action.params.level} yap`
      },

      getIdDescription: function () {
        let acc = this.accessories.find(acc => acc.id == this.action.params.id)
        return acc ? acc.name + " " : ""
      }
    }

  }
</script>

<style scoped>

</style>