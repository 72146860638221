<template>
  <span>

    <el-select style="width: 120px" size="mini"
               v-model="action.params.method"
               :placeholder="$t('triggers.select-prop')"
               @change="changeMethod">

      <el-option v-for="(method, index) in methods"
                 :key="method.value"
                 :label="$t(method.title)"
                 :value="method.value">
      </el-option>

    </el-select>


    <el-select style="width: 120px; margin-left: 5px" size="mini"
               v-model="action.params.switch"
               :placeholder="$t('triggers.select-value')"
               @change="changeSwitch">

      <el-option v-for="(value, index) in values"
                 :key="value.value"
                 :label="$t(value.title)"
                 :value="value.value">
      </el-option>

    </el-select>

  </span>
</template>

<script>
  export default {
    name: "SwitchAction",

    props: {
      action: {},
      accessory: {}
    },

    data: function(){
      return {
        methods: [
          {title: "triggers.set-power-on", value: "setPowerOn"}
        ],
        values: [
          {title: "triggers.action-on", value: true},
          {title: "triggers.action-off", value: false}
        ]
      }
    },

    /*watch: {
      'action.params.id' (val) {
        console.log("switch id", val)
        //this.setDefaultValues()
      }
    },*/


    methods: {
      changeMethod: function () {
        this.updateDescription()
        console.log("changeMethod", this.action.description)
      },

      changeSwitch: function(){
        this.updateDescription()
      },

      updateDescription: function(){
        this.action.description = this.$t("triggers.desc.action.switch", { switch: this.getSwitchDescription(), name: this.accessory.name})
      },

      getSwitchDescription: function () {
        return this.action.params.switch ? this.$t("triggers.action-on") : this.$t("triggers.action-off")
      },

      setDefaultValues: function () {
        console.log("Switch setDefaultValues")

        this.action.params.method = this.methods[0].value
        this.action.params.switch = this.values[0].value

        this.updateDescription()
      }
    },

    created(){
      console.log("switch created")

      //this.setDefaultValues()
    },

    activated(){
      console.log("switch activated")
    }

  }
</script>

<style scoped>

</style>