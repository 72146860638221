<template>
  <span>

    <el-input-number style="width: 120px" size="mini" v-model="time" @change="changeTime" />

    <el-select style="width: 100px; margin-left: 5px;" v-model="selectedUnit" size="mini">
      <el-option label="ms" value="ms" />
      <el-option label="s" value="s" />
      <el-option label="min" value="min" />
    </el-select>

  </span>
</template>

<script>
import Vue from 'vue';
export default {
  name: "DelayAction",

  props: {
    action: {}
  },

  data() {
    return {
      selectedUnit: "ms",
      unitMultipliers: {
        ms: 1,
        s: 1000,
        min: 60 * 1000
      },
      time: 0,
      maxTime: 100000, // Set your desired max time in milliseconds
    };
  },

  computed: {
    timeInMilliseconds() {
      return this.action.params.time * this.unitMultipliers[this.selectedUnit];
    },
  },

  methods: {
    changeTime: function () {
      Vue.set(this.action.params, 'time', this.time * this.unitMultipliers[this.selectedUnit]);
      Vue.set(this.action.params, 'unit', this.selectedUnit);
      if ('en' === localStorage.getItem("lang")) {
        this.action.description = `Add delay ${this.time} ${this.selectedUnit}`;
      } else {
        this.action.description = `${this.time} ${this.selectedUnit} gecikme ekle`;
      }
    },
    initializeTimeAndUnit() {
      if (this.action.params.time && this.action.params.unit) {
        this.time = this.action.params.time / this.unitMultipliers[this.action.params.unit];
        this.selectedUnit = this.action.params.unit;
      }
  }
},
  created() {
    this.initializeTimeAndUnit()
}
}

</script>

<style scoped></style>