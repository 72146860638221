<template>
  <span>

    <el-select style="width: 120px" size="mini"
               v-model="action.params.id"
               :placeholder="$t('triggers.select-accessory')"
               @change="changeId">

      <el-option v-for="(accessory, index) in data"
                 :key="accessory.id"
                 :label="accessory.name"
                 :value="accessory.id">
      </el-option>

    </el-select>

    <el-select style="width: 120px; margin-left: 5px" size="mini"
               v-model="action.params.switch"
               :placeholder="$t('triggers.select-value')"
               @change="changeSwitch">

      <el-option v-for="(value, index) in values"
                 :key="value.value"
                 :label="$t(value.title)"
                 :value="value.value">
      </el-option>

    </el-select>

  </span>
</template>

<script>
  import {group, ACCESSORY_GROUP_LIGHTING, ACCESSORY_CATEGORY_CONTACTOR, ACCESSORY_CATEGORY_SIREN} from "@/utils/accessory-utils";
  export default {
    name: "SwitchAction",

    props: {
      action: {}
    },

    data: function(){
      return {
        values: [
          {title: "triggers.action-on", value: true},
          {title: "triggers.action-off", value: false}
        ],
        data: []
      }
    },

    computed: {
      accessories: function () {
        let switches = this.$store.getters.accessories.filter(accessory => this.hasPowerOnFeature(accessory))
        console.log("switches", switches)
        return switches
      }
    },


    methods: {
      group,

      hasPowerOnFeature: function(accessory){
        return group(accessory.category) == ACCESSORY_GROUP_LIGHTING || (accessory.category >= ACCESSORY_CATEGORY_CONTACTOR && accessory.category <= ACCESSORY_CATEGORY_SIREN)
      },

      changeId: function () {
        this.updateDescription()
        console.log("changeId", this.action.description)
      },

      changeSwitch: function () {
        this.updateDescription()
        console.log("changeSwitch", this.action.description)
      },

      updateDescription: function(){
        if('en' === localStorage.getItem("lang"))
          this.action.description = this.getSwitchDescription() + " " + this.getIdDescription()
        else
          this.action.description = this.getIdDescription() + " " + this.getSwitchDescription()
      },

      getSwitchDescription: function () {
        if (this.action.params.switch == undefined)
          return ""
        return this.action.params.switch ? this.$t("triggers.action-on") : this.$t("triggers.action-off")
      },

      getIdDescription: function () {
        let acc = this.data.find(acc => acc.id == this.action.params.id)
        return acc ? acc.name : ""
      }
    },

    activated(){
      console.log("SwitchAction Activated")
    },

    created(){

      this.data = this.$store.getters.accessories.filter(accessory => this.hasPowerOnFeature(accessory))
      console.log("SwitchAction Created", this.data)
    }
  }
</script>

<style scoped>

</style>