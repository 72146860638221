<template>
  <span>

    <el-select style="width: 120px" size="mini"
               v-model="action.params.method"
               :placeholder="$t('triggers.select-prop')"
               @change="changeMethod">

      <el-option v-for="(method, index) in methods"
                 :key="method.value"
                 :label="$t(method.title)"
                 :value="method.value">
      </el-option>

    </el-select>

    <el-input v-if="action.params.method == 'setShutterLevel'" type="number" style="width: 120px; margin-left: 5px" size="mini" v-model="action.params.level" @input="changeLevel"/>

  </span>
</template>

<script>
  export default {
    name: "ShutterAction",

    props: {
      action: {},
      accessory: {}
    },

    data: function(){
      return {
        methods: [
          {title: "triggers.set-shutter-level", value: "setShutterLevel"}
        ]
      }
    },


    methods: {
      changeMethod: function () {
        this.updateDescription()
        console.log("changeMethod", this.action.description)
      },

      changeLevel: function () {
        this.updateDescription()
        console.log("changeLevel", this.action.description)
      },


      updateDescription: function(){
        this.action.description = this.$t("triggers.desc.action.shutter-level", { level: this.action.params.level, name: this.accessory.name})
      },
    },


    created(){
      console.log("shutter created")
    },

    activated(){
      console.log("shutter activated")
    }

  }
</script>

<style scoped>

</style>