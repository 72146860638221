<template>
  <span>

    <el-select style="width: 120px" size="mini"
               v-model="action.params.id"
               :placeholder="$t('triggers.select-accessory')"
               @change="changeId">

      <el-option v-for="(accessory, index) in data"
                 :key="accessory.id"
                 :label="accessory.name"
                 :value="accessory.id">
      </el-option>

    </el-select>

  </span>
</template>

<script>
  import {group, ACCESSORY_CATEGORY_PUNCH_BUTTON} from "@/utils/accessory-utils";
  export default {
    name: "PunchAction",

    props: {
      action: {}
    },

    data: function(){
      return {
        data: []
      }
    },

    computed: {
      accessories: function () {
        let switches = this.$store.getters.accessories.filter(accessory => accessory.category == ACCESSORY_CATEGORY_PUNCH_BUTTON)
        console.log("switches", switches)
        return switches
      }
    },


    methods: {
      group,

      changeId: function () {
        this.updateDescription()
        console.log("changeId", this.action.description)
      },

      updateDescription: function(){
        this.action.description = this.$t("triggers.punch") + " " + this.getIdDescription()
      },


      getIdDescription: function () {
        let acc = this.data.find(acc => acc.id == this.action.params.id)
        return acc ? acc.name : ""
      }
    },

    activated(){
      console.log("PunchAction Activated")
    },

    created(){

      this.data = this.$store.getters.accessories.filter(accessory => accessory.category == ACCESSORY_CATEGORY_PUNCH_BUTTON)
      console.log("PunchAction Created", this.data)
    }
  }
</script>

<style scoped>

</style>