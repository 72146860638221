var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "el-select",
        {
          staticStyle: { width: "120px" },
          attrs: {
            size: "mini",
            placeholder: _vm.$t("triggers.select-accessory")
          },
          on: { change: _vm.changeId },
          model: {
            value: _vm.action.params.id,
            callback: function($$v) {
              _vm.$set(_vm.action.params, "id", $$v)
            },
            expression: "action.params.id"
          }
        },
        _vm._l(_vm.data, function(accessory, index) {
          return _c("el-option", {
            key: accessory.id,
            attrs: {
              label: accessory.name + " - " + _vm.roomName(accessory.roomId),
              value: accessory.id
            }
          })
        }),
        1
      ),
      _vm.getActionComponent
        ? _c(_vm.getActionComponent, {
            tag: "component",
            staticStyle: { "margin-left": "5px" },
            attrs: { accessory: _vm.getAccessory, action: _vm.action }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }