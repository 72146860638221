var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "el-select",
        {
          staticStyle: { width: "120px" },
          attrs: {
            size: "mini",
            placeholder: _vm.$t("triggers.select-accessory")
          },
          on: { change: _vm.changeId },
          model: {
            value: _vm.action.params.id,
            callback: function($$v) {
              _vm.$set(_vm.action.params, "id", $$v)
            },
            expression: "action.params.id"
          }
        },
        _vm._l(_vm.data, function(accessory, index) {
          return _c("el-option", {
            key: accessory.id,
            attrs: { label: accessory.name, value: accessory.id }
          })
        }),
        1
      ),
      _c(
        "el-select",
        {
          staticStyle: { width: "120px", "margin-left": "5px" },
          attrs: { size: "mini", placeholder: _vm.$t("triggers.select-value") },
          on: { change: _vm.changeSwitch },
          model: {
            value: _vm.action.params.switch,
            callback: function($$v) {
              _vm.$set(_vm.action.params, "switch", $$v)
            },
            expression: "action.params.switch"
          }
        },
        _vm._l(_vm.values, function(value, index) {
          return _c("el-option", {
            key: value.value,
            attrs: { label: _vm.$t(value.title), value: value.value }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }