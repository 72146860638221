<template>
    <span>
  
      <el-select style="width: 120px" size="mini"
                 v-model="action.params.method"
                 :placeholder="$t('triggers.select-prop')"
                 @change="changeMethod">
  
        <el-option v-for="(method, index) in methods"
                   :key="method.value"
                   :label="$t(method.title)"
                   :value="method.value">
        </el-option>
  
      </el-select>
  
      <el-input v-if="action.params.method == 'setTunableBrightness'" type="number" style="width: 120px; margin-left: 5px" size="mini" v-model="action.params.brightnessValue" @input="changeBrightnessValue"/>

      <el-input v-if="action.params.method == 'setTunableColorTemperature'" type="number" style="width: 120px; margin-left: 5px" size="mini" v-model="action.params.colorAbsoluteTemperature" @input="changeColorAbsoluteTemperature"/>
  
      <el-select v-if="action.params.method == 'setPowerOn'" style="width: 120px; margin-left: 5px" size="mini"
               v-model="action.params.switch"
               :placeholder="$t('triggers.select-value')"
               @change="changeSwitch">

      <el-option v-for="(value, index) in values"
                 :key="value.value"
                 :label="$t(value.title)"
                 :value="value.value">
      </el-option>

    </el-select>
  
    </span>
  </template>
  
  <script>
    export default {
      name: "TunableWhiteAction",
  
      props: {
        action: {},
        accessory: {}
      },
  
      data: function(){
        return {
          methods: [
            {title: "triggers.set-power-on", value: "setPowerOn"},
            {title: "triggers.set-brightness", value: "setTunableBrightness"},
            {title: "triggers.set-color-absolute-temperature", value: "setTunableColorTemperature"}
          ],
          values: [
          {title: "triggers.action-on", value: true},
          {title: "triggers.action-off", value: false}
        ]
        }
      },
  
  
      computed: {
        components: function () {
          return this.accessory.components
        },
        modes: function () {
          if (this.action.params.component) {
            return this.components.find(comp => comp.type == this.action.params.component).modes
          }
          return []
        }
      },
  
  
      methods: {
      changeMethod: function () {
        this.reset()

        this.updateDescription()
      },

      changeBrightnessValue: function () {
        this.updateDescription()
      },

      changeColorAbsoluteTemperature: function () {
        this.updateDescription()
      },

      changeSwitch: function(){
        this.updateDescription()
      },

      updateDescription: function(){
        if (this.action.params.method == "setPowerOn") {
          this.action.description = this.$t("triggers.desc.action.switch", { switch: this.getSwitchDescription(), name: this.accessory.name})
        } else if (this.action.params.method == "setTunableBrightness") {
          this.action.description = this.$t("triggers.desc.action.brightness-value", { brightnessValue: this.action.params.brightnessValue, name: this.accessory.name})
        } else if (this.action.params.method == "setTunableColorTemperature") {
          this.action.description = this.$t("triggers.desc.action.color-absolute-temperature", { colorAbsoluteTemperature: this.action.params.colorAbsoluteTemperature, name: this.accessory.name}) 
        }
      },

      getSwitchDescription: function () {
        return this.action.params.switch ? this.$t("triggers.action-on") : this.$t("triggers.action-off")
      },

      reset: function () {
        if (this.action.params.method == "setPowerOn"){
          delete this.action.params.brightnessValue
          delete this.action.params.colorAbsoluteTemperature
        }
        else if (this.action.params.method == "setTunableBrightness"){
          delete this.action.params.switch
          delete this.action.params.colorAbsoluteTemperature
        }
        else if (this.action.params.method == "setTunableColorTemperature"){
          delete this.action.params.switch
          delete this.action.params.brightnessValue
        }


        console.log("reset", this.action.params)
      },

      setDefaultValues: function () {
        console.log("Dimmer setDefaultValues")
        this.action.params.method = this.methods[0].value
        this.action.params.switch = this.values[0].value

        this.updateDescription()
      }
    },
  
  
      created(){
        console.log("tunable white created")
      },
  
      activated(){
        console.log("tunable white activated")
      }
  
    }
  </script>
  
  <style scoped>
  
  </style>