var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "el-select",
        {
          staticStyle: { width: "120px" },
          attrs: {
            size: "mini",
            placeholder: _vm.$t("triggers.select-contact")
          },
          on: { change: _vm.changeId },
          model: {
            value: _vm.action.params.id,
            callback: function($$v) {
              _vm.$set(_vm.action.params, "id", $$v)
            },
            expression: "action.params.id"
          }
        },
        _vm._l(_vm.$store.getters.contacts, function(contact, index) {
          return _c("el-option", {
            key: contact.id,
            attrs: { label: contact.name, value: contact.id }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }