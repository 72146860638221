var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "el-select",
        {
          staticStyle: { width: "120px" },
          attrs: { size: "mini", placeholder: _vm.$t("triggers.select-prop") },
          on: { change: _vm.changeMethod },
          model: {
            value: _vm.action.params.method,
            callback: function($$v) {
              _vm.$set(_vm.action.params, "method", $$v)
            },
            expression: "action.params.method"
          }
        },
        _vm._l(_vm.methods, function(method, index) {
          return _c("el-option", {
            key: method.value,
            attrs: { label: _vm.$t(method.title), value: method.value }
          })
        }),
        1
      ),
      _vm.action.params.method == "setTargetPoint"
        ? _c("el-input", {
            staticStyle: { width: "120px", "margin-left": "5px" },
            attrs: { type: "number", size: "mini" },
            on: { input: _vm.changeSetPoint },
            model: {
              value: _vm.action.params.setPoint,
              callback: function($$v) {
                _vm.$set(_vm.action.params, "setPoint", $$v)
              },
              expression: "action.params.setPoint"
            }
          })
        : _vm._e(),
      _vm.action.params.method == "setThermostatMode"
        ? _c(
            "span",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "120px", "margin-left": "5px" },
                  attrs: {
                    size: "mini",
                    placeholder: _vm.$t("triggers.select-value")
                  },
                  on: { change: _vm.changeComponent },
                  model: {
                    value: _vm.action.params.component,
                    callback: function($$v) {
                      _vm.$set(_vm.action.params, "component", $$v)
                    },
                    expression: "action.params.component"
                  }
                },
                _vm._l(_vm.components, function(component, index) {
                  return _c("el-option", {
                    key: component.type,
                    attrs: { label: component.title, value: component.type }
                  })
                }),
                1
              ),
              _vm.modes.length > 0
                ? _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px", "margin-left": "5px" },
                      attrs: {
                        size: "mini",
                        placeholder: _vm.$t("triggers.select-value")
                      },
                      on: { change: _vm.changeMode },
                      model: {
                        value: _vm.action.params.mode,
                        callback: function($$v) {
                          _vm.$set(_vm.action.params, "mode", $$v)
                        },
                        expression: "action.params.mode"
                      }
                    },
                    _vm._l(_vm.modes, function(mode, index) {
                      return _c("el-option", {
                        key: mode.type,
                        attrs: { label: mode.title, value: mode.type }
                      })
                    }),
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }