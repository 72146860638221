<template>
  <span>

    <el-input style="width: 260px; margin-left: 5px"
              size="mini"
              type="text"
              v-model="action.params.url"
              placeholder="http://"
              @input="changeUrl">
    </el-input>

  </span>
</template>

<script>

  export default {
    name: "HttpRequestAction",
    props: {
      action: {}
    },


    methods: {
      changeUrl: function () {
        this.updateDescription()
        console.log("changeDpt", this.action.description)
      },

      updateDescription: function () {
        if('en' === localStorage.getItem("lang"))
          this.action.description = `Send Http request to ${this.action.params.url}`
        else
          this.action.description = `${this.action.params.url} adresine Http isteği gönder`
      }
    }
  }
</script>

<style scoped>

</style>