<template>
  <span>
    <el-select style="width: 120px" size="mini"
               v-model="action.params.id"
               :placeholder="$t('triggers.select-accessory')"
               @change="changeId">

      <el-option v-for="(accessory, index) in data"
                 :key="accessory.id"
                 :label="accessory.name + ' - ' + roomName(accessory.roomId)"
                 :value="accessory.id">
      </el-option>

    </el-select>

    <component v-if="getActionComponent" style="margin-left: 5px;" :is="getActionComponent" :accessory="getAccessory" :action="action"></component>

  </span>
</template>

<script>
  import {ACCESSORY_GROUP_LIGHTING, ACCESSORY_GROUP_HVAC, ACCESSORY_GROUP_SHUTTER, ACCESSORY_GROUP_ONOFF, ACCESSORY_CATEGORY_SHUTTER, ACCESSORY_CATEGORY_DIMMER, ACCESSORY_CATEGORY_PUNCH_BUTTON,
    ACCESSORY_CATEGORY_BINARY_BUTTON, ACCESSORY_CATEGORY_ADVANCED_THERMOSTAT, ACCESSORY_CATEGORY_CONTACTOR, ACCESSORY_CATEGORY_SIREN, ACCESSORY_CATEGORY_WATER_VALVE,
    ACCESSORY_CATEGORY_ELECTRICAL_DEVICE, ACCESSORY_CATEGORY_ELEVATOR, ACCESSORY_CATEGORY_GAS_VALVE, ACCESSORY_CATEGORY_SWITCH, ACCESSORY_CATEGORY_THERMOSTAT, ACCESSORY_CATEGORY_TUNABLE_WHITE,
    ACCESSORY_CATEGORY_AIR_CONDITIONER} from "@/utils/accessory-utils";

  import DimmerAction from "./accessory/DimmerAction"
  import SwitchAction from "./accessory/SwitchAction"
  import ShutterAction from "./accessory/ShutterAction"
  import BinaryAction from "./accessory/BinaryAction"
  import PunchAction from "./accessory/PunchAction"
  import ThermostatAction from "./accessory/ThermostatAction"
  import TunableWhiteAction from "./accessory/TunableWhiteAction.vue"

  export default {
    name: "AccessoryAction",

    components: {
      SwitchAction,
      DimmerAction,
      ShutterAction,
      BinaryAction,
      PunchAction,
      ThermostatAction,
      TunableWhiteAction
    },

    props: {
      action: {}
    },

    data: function(){
      return {
        data: []
      }
    },


    computed: {
      getActionComponent: function () {
        const accessory = this.data.find(accessory => accessory.id == this.action.params.id)

        if (accessory) {
          switch (accessory.category) {
            case ACCESSORY_CATEGORY_SWITCH: case ACCESSORY_CATEGORY_CONTACTOR: case ACCESSORY_CATEGORY_WATER_VALVE:
            case ACCESSORY_CATEGORY_GAS_VALVE: case ACCESSORY_CATEGORY_ELEVATOR: case ACCESSORY_CATEGORY_ELECTRICAL_DEVICE: case ACCESSORY_CATEGORY_SIREN:
            return "SwitchAction"
            case ACCESSORY_CATEGORY_PUNCH_BUTTON:
              return "PunchAction"
            case ACCESSORY_CATEGORY_BINARY_BUTTON:
              return "BinaryAction"
            case ACCESSORY_CATEGORY_DIMMER:
              return "DimmerAction"
              case ACCESSORY_CATEGORY_TUNABLE_WHITE:
              return "TunableWhiteAction"
            case ACCESSORY_CATEGORY_THERMOSTAT: case ACCESSORY_CATEGORY_AIR_CONDITIONER: case ACCESSORY_CATEGORY_ADVANCED_THERMOSTAT:
            return "ThermostatAction"
            case ACCESSORY_CATEGORY_SHUTTER:
              return "ShutterAction"
            default:
              return ""
          }
        }
      },

      getAccessory(){
        return this.data.find(accessory => accessory.id == this.action.params.id)
      }
    },


    methods: {
      updateDescription: function(){
        const id = this.action.params.id
        this.action.params = {}
        this.action.params.id = id

        const comp = this.getActionComponent
        if (comp == "SwitchAction") {
          this.action.params.method = "setPowerOn"
          this.action.params.switch = true
        } else if (comp == "DimmerAction") {
          this.action.params.method = "setDimmerLevel"
          this.action.params.level = 0
        }
      },
      changeId: function () {
        const id = this.action.params.id
        this.action.params = {}
        this.action.params.id = id
      },
      roomName(roomId) {
      const room = this.$store.getters.rooms.find((room) => room.id == roomId);
      return room ? room.name : roomId;
      },
    },

    created(){
      this.data = this.$store.getters.accessories.filter(accessory => accessory.group == ACCESSORY_GROUP_LIGHTING || accessory.group == ACCESSORY_GROUP_HVAC
        || accessory.group == ACCESSORY_GROUP_SHUTTER || accessory.group == ACCESSORY_GROUP_ONOFF)
    }
  }
</script>

<style scoped>

</style>