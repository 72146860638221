<template>
  <span>

    <el-select style="width: 120px"
               size="mini"
               v-model="action.params.dpt"
               placeholder="DPT"
               @change="changeDpt">

      <el-option v-for="dpt in dataPoints" :label="dpt.title" :value="dpt.value" :key="dpt.value">
      </el-option>
    </el-select>

    <el-input style="width: 120px; margin-left: 5px"
              size="mini"
              type="text"
              v-model="action.params.address"
              placeholder=".../.../..."
              v-mask="groupAddressMask"
              @input="changeAddress">
    </el-input>


    <el-input style="width: 120px; margin-left: 5px"
              size="mini"
              type="text"
              v-model="action.params.value"
              v-mask="maskDecimal"
              :placeholder="$t('triggers.write-value')"
              @input="changeValue">
    </el-input>

  </span>
</template>

<script>
  import {knxMixin} from "../../../../accessory/components/knx/knxMixin";

  export default {
    name: "KnxValueAction",

    mixins: [knxMixin],

    props: {
      action: {}
    },

    watch: {
      "action.params.address": function (val) {
        // this.updateDescription()
        // console.log("changeAddress", this.action.description)
      },
      "action.params.value": function (val) {
        // this.updateDescription()
        // console.log("changeValue", this.action.description)
      }
    },

    methods: {
      changeDpt: function () {
        this.updateDescription()
        console.log("changeDpt", this.action.description)
      },
      changeAddress: function () {
        this.updateDescription()
        console.log("changeAddress", this.action.description)
      },
      changeValue: function () {
        this.updateDescription()
        console.log("changeValue", this.action.description)
      },

      updateDescription: function () {
        let dpt = this.action.params.dpt ? `Dpt:${this.action.params.dpt}` : ""

        let address;
        let value;

        if('en' === localStorage.getItem("lang")){
          address = this.action.params.address ? `Address:${this.action.params.address}` : ""
          value = this.action.params.value ? `Value:${this.action.params.value}` : ""
          this.action.description = `Send Knx Value ( ${dpt}, ${address}, ${value} )`
        }
        else{
          address = this.action.params.address ? `Adres:${this.action.params.address}` : ""
          value = this.action.params.value ? `Değer:${this.action.params.value}` : ""
          this.action.description = `Knx Değeri Gönder ( ${dpt}, ${address}, ${value} )`
        }


      }
    }
  }
</script>

<style scoped>

</style>