<template>
  <span>

    <el-select style="width: 120px" size="mini"
               v-model="action.params.id"
               :placeholder="$t('triggers.select-accessory')"
               @change="changeId">

      <el-option v-for="(accessory, index) in accessories"
                 :key="accessory.id"
                 :label="accessory.name"
                 :value="accessory.id">
      </el-option>

    </el-select>

    <el-input type="number" style="width: 120px; margin-left: 5px" size="mini" v-model="action.params.setPoint" @input="changeSetPoint"/>

  </span>
</template>

<script>
  import {group, ACCESSORY_GROUP_HVAC} from "@/utils/accessory-utils";
  export default {
    name: "SetPointAction",

    props: {
      action: {}
    },


    computed: {
      accessories: function () {
        return this.$store.getters.accessories.filter(accessory => group(accessory.category) == ACCESSORY_GROUP_HVAC)
      }
    },

    methods: {
      group,

      changeId: function () {
        this.updateDescription()
        console.log("changeId", this.action.description)
      },

      changeSetPoint: function () {
        this.updateDescription()
        console.log("changeSetPoint", this.action.description)
      },

      updateDescription: function(){
        this.action.description = this.getSetPointDescription()
      },

      getSetPointDescription: function () {
        if (this.action.params.setPoint == undefined)
          return this.getIdDescription()
        if('en' === localStorage.getItem("lang"))          
          return `Change ${this.getIdDescription()}temperature to ${this.action.params.setPoint}&#176;`
        else
          return `${this.getIdDescription()}sıcaklığını ${this.action.params.setPoint}&#176; yap`
      },

      getIdDescription: function () {
        let acc = this.accessories.find(acc => acc.id == this.action.params.id)
        return acc ? acc.name + " " : ""
      }
    }

  }
</script>

<style scoped>

</style>