<template>
  <span>

    <el-select style="width: 120px" size="mini"
               v-model="action.params.method"
               :placeholder="$t('triggers.select-prop')"
               @change="changeMethod">

      <el-option v-for="(method, index) in methods"
                 :key="method.value"
                 :label="$t(method.title)"
                 :value="method.value">
      </el-option>

    </el-select>

    <el-input v-if="action.params.method == 'setDimmerLevel'" type="number" style="width: 120px; margin-left: 5px" size="mini" v-model="action.params.brightness" @input="changeLevel"/>

    <el-select v-if="action.params.method == 'setPowerOn'" style="width: 120px; margin-left: 5px" size="mini"
               v-model="action.params.switch"
               :placeholder="$t('triggers.select-value')"
               @change="changeSwitch">

      <el-option v-for="(value, index) in values"
                 :key="value.value"
                 :label="$t(value.title)"
                 :value="value.value">
      </el-option>

    </el-select>

  </span>
</template>

<script>
  export default {
    name: "DimmerAction",

    props: {
      action: {},
      accessory: {}
    },

    data: function(){
      return {
        methods: [
          {title: "triggers.set-power-on", value: "setPowerOn"},
          {title: "triggers.set-dimmer-level", value: "setDimmerLevel"}
        ],
        values: [
          {title: "triggers.action-on", value: true},
          {title: "triggers.action-off", value: false}
        ]
      }
    },

    /*watch: {
      'action.params.id' (val) {
        console.log("dimmer id", val)
        //this.setDefaultValues()
      }
    },*/

    methods: {
      changeMethod: function () {
        this.reset()

        this.updateDescription()
        console.log("changeMethod", this.action.description)
      },

      changeLevel: function () {
        this.updateDescription()
        console.log("changeLevel", this.action.description)
      },

      changeSwitch: function(){
        this.updateDescription()
      },

      updateDescription: function(){
        if (this.action.params.method == "setPowerOn") {
          this.action.description = this.$t("triggers.desc.action.switch", { switch: this.getSwitchDescription(), name: this.accessory.name})
        } else if (this.action.params.method == "setDimmerLevel") {
          this.action.description = this.$t("triggers.desc.action.dim-level", { level: this.action.params.level, name: this.accessory.name})
        }
      },

      getSwitchDescription: function () {
        return this.action.params.switch ? this.$t("triggers.action-on") : this.$t("triggers.action-off")
      },

      reset: function () {
        if (this.action.params.method == "setPowerOn")
          delete this.action.params.level
        else if (this.action.params.method == "setDimmerLevel")
          delete this.action.params.switch

        console.log("reset", this.action.params)
      },

      setDefaultValues: function () {
        console.log("Dimmer setDefaultValues")
        this.action.params.method = this.methods[0].value
        this.action.params.switch = this.values[0].value

        this.updateDescription()
      }
    },


    created(){
      console.log("dimmer created")
      //this.setDefaultValues()
    },

    activated(){
      console.log("dimmer activated")
    }

  }
</script>

<style scoped>

</style>