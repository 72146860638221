<template>
  <span>

    <el-select style="width: 120px" size="mini"
               v-model="action.params.id"
               :placeholder="$t('triggers.select-contact')"
               @change="changeId">

      <el-option v-for="(contact, index) in $store.getters.contacts"
                 :key="contact.id"
                 :label="contact.name"
                 :value="contact.id">
      </el-option>

    </el-select>


  </span>
</template>

<script>
  export default {
    name: "ContactAction",

    props: {
      action: {}
    },

    methods: {
      changeId: function () {
        if (!this.action.params.id)
          this.action.description = ""

        let contact = this.$store.getters.contacts.find(contact => contact.id == this.action.params.id)
        if (!contact)
          this.action.description = ""

        if('en' === localStorage.getItem("lang"))
          this.action.description = `Call ${contact.name}`
        else
          this.action.description = `${contact.name} kişisini ara`
        console.log("changeId", this.action.description)
      },
    }
  }
</script>

<style scoped>

</style>