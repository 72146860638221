<template>
  <span>

    <el-select style="width: 120px" size="mini"
               v-model="action.params.method"
               :placeholder="$t('triggers.select-prop')"
               @change="changeMethod">

      <el-option v-for="(method, index) in methods"
                 :key="method.value"
                 :label="$t(method.title)"
                 :value="method.value">
      </el-option>

    </el-select>


  </span>
</template>

<script>
  export default {
    name: "PunchAction",

    props: {
      action: {},
      accessory: {}
    },

    data: function(){
      return {
        methods: [
          {title: "triggers.punch", value: "punch"}
        ]
      }
    },


    methods: {
      changeMethod: function () {
        this.updateDescription()
        console.log("changeMethod", this.action.description)
      },


      updateDescription: function(){
        this.action.description = this.$t("triggers.desc.action.punch", { punch: this.$t("triggers.punch"), name: this.accessory.name})
      },
    },

    created(){
      console.log("punch created")
    },

    activated(){
      console.log("punch activated")
    }

  }
</script>

<style scoped>

</style>