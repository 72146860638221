<template>
  <span>

    <el-input style="width: 160px; margin-left: 5px"
              size="mini"
              type="text"
              v-model="action.params.title"
              :placeholder="$t('triggers.title')"
              @input="changeTitle">
    </el-input>

    <el-input style="width: 300px; margin-left: 5px"
              size="mini"
              type="text"
              v-model="action.params.message"
              :placeholder="$t('triggers.message')"
              @input="changeMessage">
    </el-input>

  </span>
</template>

<script>
export default {
  name: "PushNotificationAction",
  props: {
    action: {},
  },

  watch: {
    "action.params.message": function(newVal, oldVal) {
      console.log("message", newVal);
    },
    "action.params.title": function(newVal, oldVal) {
      console.log("title", newVal);
    },
  },

  methods: {
    changeTitle: function() {
      this.action.params.title = this.action.params.title.replace(
        "[coreos_version]",
        this.$store.getters.coreosVersion
      );
      this.action.params.title = this.action.params.title.replace(
        "[serial_number]",
        this.$store.getters.serialNumber
      );
      this.action.params.title = this.action.params.title.replace(
        "[product_code]",
        this.$store.getters.productCode
      );
      this.action.params.title = this.action.params.title.replace(
        "[brand]",
        this.$store.getters.brand
      );
      this.updateDescription();
      console.log("changeTitle", this.action.description);
    },

    changeMessage: function() {
      this.action.params.message = this.action.params.message.replace(
        "[coreos_version]",
        this.$store.getters.coreosVersion
      );
      this.action.params.message = this.action.params.message.replace(
        "[serial_number]",
        this.$store.getters.serialNumber
      );
      this.action.params.message = this.action.params.message.replace(
        "[product_code]",
        this.$store.getters.productCode
      );
      this.action.params.message = this.action.params.message.replace(
        "[brand]",
        this.$store.getters.brand
      );
      this.updateDescription();
      console.log("changeMessage", this.action.description);
    },

    updateDescription: function() {
      if ("en" === localStorage.getItem("lang"))
        this.action.description = `Send Push Notification ( ${
          this.action.params.title
        }, ${this.action.params.message} )`;
      else
        this.action.description = `Push Bildirimi Gönder ( ${
          this.action.params.title
        }, ${this.action.params.message} )`;
    },
  },
};
</script>

<style scoped>
</style>
