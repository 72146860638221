var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "el-select",
        {
          staticStyle: { width: "120px" },
          attrs: { size: "mini", placeholder: _vm.$t("triggers.select-prop") },
          on: { change: _vm.changeMethod },
          model: {
            value: _vm.action.params.method,
            callback: function($$v) {
              _vm.$set(_vm.action.params, "method", $$v)
            },
            expression: "action.params.method"
          }
        },
        _vm._l(_vm.methods, function(method, index) {
          return _c("el-option", {
            key: method.value,
            attrs: { label: _vm.$t(method.title), value: method.value }
          })
        }),
        1
      ),
      _vm.action.params.method == "setDimmerLevel"
        ? _c("el-input", {
            staticStyle: { width: "120px", "margin-left": "5px" },
            attrs: { type: "number", size: "mini" },
            on: { input: _vm.changeLevel },
            model: {
              value: _vm.action.params.brightness,
              callback: function($$v) {
                _vm.$set(_vm.action.params, "brightness", $$v)
              },
              expression: "action.params.brightness"
            }
          })
        : _vm._e(),
      _vm.action.params.method == "setPowerOn"
        ? _c(
            "el-select",
            {
              staticStyle: { width: "120px", "margin-left": "5px" },
              attrs: {
                size: "mini",
                placeholder: _vm.$t("triggers.select-value")
              },
              on: { change: _vm.changeSwitch },
              model: {
                value: _vm.action.params.switch,
                callback: function($$v) {
                  _vm.$set(_vm.action.params, "switch", $$v)
                },
                expression: "action.params.switch"
              }
            },
            _vm._l(_vm.values, function(value, index) {
              return _c("el-option", {
                key: value.value,
                attrs: { label: _vm.$t(value.title), value: value.value }
              })
            }),
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }