var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "el-select",
        {
          staticStyle: { width: "120px" },
          attrs: {
            size: "mini",
            placeholder: _vm.$t("triggers.select-accessory")
          },
          on: { change: _vm.changeId },
          model: {
            value: _vm.action.params.id,
            callback: function($$v) {
              _vm.$set(_vm.action.params, "id", $$v)
            },
            expression: "action.params.id"
          }
        },
        _vm._l(_vm.data, function(accessory, index) {
          return _c("el-option", {
            key: accessory.id,
            attrs: { label: accessory.name, value: accessory.id }
          })
        }),
        1
      ),
      _c(
        "el-select",
        {
          staticStyle: { width: "120px", "margin-left": "5px" },
          attrs: { size: "mini", placeholder: _vm.$t("triggers.select-value") },
          on: { change: _vm.changeComponent },
          model: {
            value: _vm.action.params.component,
            callback: function($$v) {
              _vm.$set(_vm.action.params, "component", $$v)
            },
            expression: "action.params.component"
          }
        },
        _vm._l(_vm.components, function(component, index) {
          return _c("el-option", {
            key: component.type,
            attrs: { label: component.title, value: component.type }
          })
        }),
        1
      ),
      _vm.modes.length > 0
        ? _c(
            "el-select",
            {
              staticStyle: { width: "120px", "margin-left": "5px" },
              attrs: {
                size: "mini",
                placeholder: _vm.$t("triggers.select-value")
              },
              on: { change: _vm.changeMode },
              model: {
                value: _vm.action.params.mode,
                callback: function($$v) {
                  _vm.$set(_vm.action.params, "mode", $$v)
                },
                expression: "action.params.mode"
              }
            },
            _vm._l(_vm.modes, function(mode, index) {
              return _c("el-option", {
                key: mode.type,
                attrs: { label: mode.title, value: mode.type }
              })
            }),
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }