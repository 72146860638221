<template>
  <span>

    <el-select style="width: 120px" size="mini"
               v-model="action.params.id"
               :placeholder="$t('triggers.select-accessory')"
               @change="changeId">

      <el-option v-for="(accessory, index) in data"
                 :key="accessory.id"
                 :label="accessory.name"
                 :value="accessory.id">
      </el-option>

    </el-select>

    <el-select style="width: 120px; margin-left: 5px" size="mini"
               v-model="action.params.component"
               :placeholder="$t('triggers.select-value')"
               @change="changeComponent">

      <el-option v-for="(component, index) in components"
                 :key="component.type"
                 :label="component.title"
                 :value="component.type">
      </el-option>

    </el-select>

    <el-select v-if="modes.length > 0" style="width: 120px; margin-left: 5px" size="mini"
               v-model="action.params.mode"
               :placeholder="$t('triggers.select-value')"
               @change="changeMode">

      <el-option v-for="(mode, index) in modes"
                 :key="mode.type"
                 :label="mode.title"
                 :value="mode.type">
      </el-option>

    </el-select>

  </span>
</template>

<script>
  import {group, ACCESSORY_CATEGORY_ADVANCED_THERMOSTAT} from "@/utils/accessory-utils";
  export default {
    name: "ThermostatModeAction",

    props: {
      action: {}
    },

    data: function(){
      return {
        data: []
      }
    },

    computed: {
      accessories: function () {
        let switches = this.$store.getters.accessories.filter(accessory => accessory.category == ACCESSORY_CATEGORY_ADVANCED_THERMOSTAT)
        console.log("switches", switches)
        return switches
      },
      components: function () {
        let thermostat = this.$store.getters.accessories.find(accessory => accessory.id == this.action.params.id)
        if (thermostat) {
          return thermostat.components
        }
        return []
      },
      modes: function () {
        if (this.action.params.component) {
          return this.components.find(comp => comp.type == this.action.params.component).modes
        }
        return []
      }
    },



    methods: {
      group,

      changeId: function () {
        if (this.components.length > 0)
          this.action.params.component = this.components[0].type


        this.updateDescription()
        console.log("changeId", this.action.description)
      },

      changeComponent: function () {
        if (this.modes.length > 0)
          this.action.params.mode = this.modes[0].type

        this.updateDescription()
        console.log("changeComponent", this.action.description)
      },

      changeMode: function () {
        this.updateDescription()
        console.log("changeMode", this.action.description)
      },

      updateDescription: function(){
        if('en' === localStorage.getItem("lang"))
          this.action.description = "Set " + this.getComponentDescription() + " of " + this.getIdDescription() + " to " + this.getModeDescription()
        else
          this.action.description = this.getIdDescription() + " için " + this.getComponentDescription() + " bileşenini " + this.getModeDescription() + " olarak ayarla"
      },

      getComponentDescription: function () {
        if (this.action.params.component == undefined)
          return "..."
        const component = this.components.find(comp => comp.type == this.action.params.component)
        return component ? component.title : "..."
      },

      getModeDescription: function () {
        if (this.action.params.mode == undefined)
          return "..."
        const mode = this.modes.find(mode => mode.type == this.action.params.mode)
        return mode ? mode.title : "..."
      },

      getIdDescription: function () {
        let acc = this.data.find(acc => acc.id == this.action.params.id)
        return acc ? acc.name : ""
      }
    },

    activated(){
      console.log("ThermostatModeAction Activated")
    },

    created(){

      this.data = this.$store.getters.accessories.filter(accessory => accessory.category == ACCESSORY_CATEGORY_ADVANCED_THERMOSTAT)
      console.log("ThermostatModeAction Created", this.data)
    }
  }
</script>

<style scoped>

</style>